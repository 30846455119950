<template>
    <Section
        id="rakna-pa-kostnaden"
        width="md"
        class="bg-blue"
    >
        <h2>
            {{ 
                $translate('lc_h2', 'Hur mycket kostar {title}?')
                    .replace('{title}', document.data.title) 
            }}
        </h2>
        <p>{{ $translate('lc_p', 'Justera reglagen för att se hur beloppet, löptiden och räntenivån påverkar din månadskostnad.') }}</p>

        <div class="container">
            <div class="container-inner">
                <Slider
                    v-for="slider in slidersConfig"
                    :key="slider.calcKey"
                    :label="slider.label"
                    :suffix="slider.suffix"
                    :max-value="slider.maxValue"
                    :min-value="slider.minValue"
                    :start-value="slider.startValue"
                    :increment="slider.increment"
                    :calc-key="slider.calcKey"
                    :interest-type="slider.interestType"
                    :force-disable-slider="slider.forceDisableSlider"
                    @emit="setValues"
                />
            </div>

            <div class="container-inner">
                <span class="header">{{ $translate('lc_results', 'Resultat') }}</span>
                <ul>
                    <li>
                        <span>{{ $translate('lc_interest_cost', 'Räntekostnad') }}</span>
                        <span>{{ $format.currency(interestCost, 2) }}</span>
                    </li>
                    <li>
                        <span>{{ $translate('lc_fees', 'Avgifter') }}</span>
                        <span>{{ $format.currency(fees, 2) }}</span>
                    </li>
                    <li>
                        <span>{{ $translate('lc_total_payback', 'Belopp att betala tillbaka') }}</span>
                        <span>{{ $format.currency(amountToPayBack, 2) }}</span>
                    </li>
                    <li class="highlight">
                        <span>{{ $translate('lc_monthly_cost', 'Månadskostnad') }}</span>
                        <span>
                            {{ $format.currency(monthlyPayment, 2) }}
                        </span>
                    </li>
                </ul>

                <div class="example">
                    {{ 
                        $translate('lc_example', 'Efter ränteavdrag kostar lånet totalt {cost} efter ett ränteavdrag på {interestDeduction}. Över {sliderDuration} månader innebär det en totalkostnad på {costPerMonth} per månad.')
                            .replace('{cost}', $format.currency(loanCostAfterInterestDeduction, 2))
                            .replace('{interestDeduction}', $format.currency(interestDeduction, 2))
                            .replace('{sliderDuration}', sliderDuration)
                            .replace('{costPerMonth}', $format.currency(loanCostAfterInterestDeductionPerMonth, 2)) 
                    }}
                </div>

                <div class="example">
                    {{ 
                        $translate('lc_example_disclaimer', 'Räkneexemplet ovan är en uppskattning av kreditkostnaden hos {title} och ska inte ses som ett erbjudande. Det är alltid villkoren som framgår på långivarens hemsida som gäller.')
                            .replace('{title}', document.data.title) 
                    }}
                </div>
            </div>
        </div>
    </Section>
</template>

<script>
import Slider from '@/components/features/Slider';
export default {
    components: { Slider },
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        },
        model: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        const sliderDuration = this.model.absoluteMinDuration;
        const sliderAmount = this.model.absoluteMinAmount;
        const sliderInterest = this.model._data.interest_fixed || this.model._data.interest_interval_min;
        const slidersConfig = [
            {
                label: this.$translate('loan_amount', 'Lånebelopp'),
                suffix: 'kr',
                maxValue: this.model.absoluteMaxAmount,
                minValue: this.model.absoluteMinAmount,
                startValue: sliderAmount,
                increment: '1000',
                calcKey: 'sliderAmount',
            },
            {
                label: this.$translate('duration', 'Löptid'),
                suffix: 'mån',
                maxValue: this.model.absoluteMaxDuration,
                minValue: this.model.absoluteMinDuration,
                startValue: sliderDuration,
                increment: '1',
                calcKey: 'sliderDuration',
            },
            {
                label: this.$translate('interest', 'Ränta'),
                suffix: '%',
                maxValue: this.model._data.interest_interval_max,
                minValue: this.model._data.interest_interval_min,
                startValue: sliderInterest,
                increment: '0.01',
                calcKey: 'sliderInterest',
                interestType: this.model.interestType,
                forceDisableSlider: this.model.interestType === 'fixed'
            }
        ];
        return {
            // "constants"
            fees: this.model.administrationFee + this.model.setupFee || 0,

            // slider
            sliderDuration,
            sliderAmount,
            sliderInterest,
            slidersConfig,

            // calculations
            interestCost: 0,
            interestDeduction: 0,
            amountToPayBack: 0,
            monthlyPayment: 0,
            beforeInterestDeduction: 0,
            loanCostAfterInterestDeduction: 0,
            loanCostAfterInterestDeductionPerMonth: 0,
        };
    },
    mounted() {
        this.calculate();
    },
    methods: {
        setValues(value, key) {
            this[ key ] = value;
            this.calculate();
        },

        calculate() {
            this.interestCost = this.$calculateInterestCost(
                this.sliderAmount,
                this.sliderInterest / 100,
                this.sliderDuration
            );

            this.interestDeduction = this.interestCost * 0.3;
            this.amountToPayBack = this.sliderAmount + this.interestCost + this.fees;
            this.monthlyPayment = this.amountToPayBack / this.sliderDuration;

            this.loanCostAfterInterestDeduction = this.sliderAmount + this.interestCost - this.interestDeduction + this.fees;
            this.loanCostAfterInterestDeductionPerMonth = this.loanCostAfterInterestDeduction / this.sliderDuration;
        }
    }
};
</script>

<style lang="scss" scoped>
section::v-deep {
    .section-inner {

        h2,
        p {
            color: $white;
            text-align: center;
        }
        p { @include spacing(margin, 6, top);}

        .container {
            border-top: 1px solid rgba(255,255,255,.25);
            padding-top: 30px;
            width: 100%;
            @include spacing(margin, 4, top);
            @include grid(1, 40px);

            @include device(pad) {
                @include grid(2, 40px);
            }

            &-inner:first-child {
                background: $white;
                @include spacing(padding, 6);
                @include border-radius(3px);
                box-shadow: $bxs;
                @include grid(1, 40px);
                @include device(desktop) {
                    gap: 50px;
                }
            }

            &-inner:last-child {
                color: $white;

                .header {
                    font-size: 22px;
                    font-family: $fontHeader;
                    text-align: center;
                    width: 100%;
                    display: block;
                    @include spacing(margin, 6, bottom);
                }

                ul {
                    li {
                        font-size: 14px;
                        display: flex;
                        justify-content: center;
                        span {
                            flex: 1;
                            padding: 10px;
                            font-weight: lighter;
                        }
                        span:first-child { text-align: right; }
                        span:last-child {
                            text-align: left;
                            font-weight: bold;
                        }
                    }
                    li.highlight {
                        font-weight: bold;
                        font-size: 18px;
                        background: rgba(255,255,255,.1);
                        border-radius: 2px;
                    }
                }

                .example {
                    font-size: 12px;
                    line-height: 20px;
                    font-family: $fontText;
                    font-weight: lighter;
                    font-style: italic;
                    margin-top: 20px;
                }
            }
        }
    }
}
</style>
