import RangeSlider from 'vue-range-slider';
import 'vue-range-slider/dist/vue-range-slider.css';
export default {
    components: { RangeSlider },
    props: {
        label: {
            type: String,
            required: false,
            default: String
        },
        suffix: {
            type: String,
            required: false,
            default: String
        },
        increment: {
            type: String,
            required: false,
            default: String
        },
        maxValue: {
            type: Number,
            required: false,
            default: Number
        },
        minValue: {
            type: Number,
            required: false,
            default: Number
        },
        startValue: {
            type: Number,
            required: false,
            default: Number
        },
        calcKey: {
            type: String,
            required: false,
            default: String
        },
        forceDisableSlider: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            sliderValue: this.startValue
        };
    },
    computed: {
        sliderDisabled() {
            return this.minValue === this.maxValue || this.forceDisableSlider;
        },
        numberOfDecimals() {
            return this.calcKey === 'sliderInterest' ? 2 : 0;
        },
        formattedValue() {
            return `${this.$format.number(this.sliderValue, this.numberOfDecimals)} ${this.suffix}`;
        }
    },
};