<template>
    <div 
        class="slider"
        :class="{ sliderDisabled: sliderDisabled }"
    >
        <div class="slider-label">
            <label>{{ label }}</label>
        </div>

        <div class="slider-amount">
            <span>{{ formattedValue }}</span>
            <span v-if="sliderDisabled" class="slider-disabled-text">{{ $translate('cannot_be_adjusted', 'Kan inte justeras') }}</span>
        </div>
        <range-slider
            v-model="sliderValue"
            class="range-slider"
            :min="minValue"
            :max="maxValue"
            :step="increment"
        />
    </div>
</template>

<script>
import SliderMixins from './SliderMixins.js';
export default {
    mixins: [SliderMixins],
    watch: {
        sliderValue() {
            this.$emit('emit', this.sliderValue, this.calcKey);
        }
    }
};
</script>

<style lang="scss">
.slider {
    font-family: $fontText;
    @include grid(2, 20px);

    &.sliderDisabled {
        user-select: none!important;
        pointer-events: none!important;
        .range-slider-knob {
            background: #b1b1b1;
        }
    }
    .slider-label {
        height: 100%;
        @include flex;
    }

    span {
        display: block;
    }

    label {
        width: 100%;
        display: block;
        font-size: 14px;
        font-weight: bold;
    }

    .slider-amount {
        padding: 10px;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        font-size: 14px;
        @include flex;
        flex-direction: column;
        gap: 0.5rem;
        font-weight: bold;
        box-shadow: $bxs;

        .slider-disabled-text {
            text-align: center;
            font-weight: normal;
        }
    }

    .range-slider {
        width: 100%;
        padding: 0 1px;
        grid-column: 1 / 3;
    }

    .range-slider-fill {
        background: #e6e6e6;
        height: 10px;
        border-radius: 5px;
    }

    .range-slider-rail {
        height: 10px;
        background: #e6e6e6;
        border-radius: 5px;
    }

    .range-slider-knob {
        height: 25px;
        width: 25px;
        border: 2px solid #fff;
        background: $green;
        box-shadow: none;
    }
}
</style>
